import React from 'react';
import {useState} from 'react';
import {motion} from 'framer-motion';
import {useTheme} from 'styled-components';

import {MotionDiv, MotionImage} from '../../animations/components';
import {animationContainer, fadeInLeft, fadeInRight, fadeInUp} from '../../animations/variants';
import {BookADemo} from '../BookADemo';
import ContactUsForm from '../contact-us-form/ContactUsForm';
import {ScContainer} from '../container/styled';
import {CustomButton} from '../custom-button';
import Modal from '../modal';
import {ScParagraph} from '../Paragraph/styled';
import {ScTitle} from '../Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import {useMatchMedia} from '../../hooks/use-match-media';
import {ScReadyToStartSavingButtonsWrapper} from '../../sections/Home/styled';

import {ScBrownTextWrapper, ScBrownWrapper, ScImgWrapper} from './styled';
import {TypeOfScheme} from "@utils";


interface IBrownInfo {
	title: string;
	text: string;
	image: string;
	link: string;
	typeOfScheme: TypeOfScheme;
}

export const BrownInfo = ({ title, text, image, link, typeOfScheme }: IBrownInfo) => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);
	const { ref, controls } = useContentAnimation();
	const [isOpenModal, setIsOpenModal] = useState(false);

	return (
		<>
      {isOpenModal &&
        <Modal open={isOpenModal} title={'Contact us'} handleClose={() => setIsOpenModal(false)}>
          <ContactUsForm />
        </Modal>
      }
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<ScContainer>
					<ScBrownWrapper>
						<ScBrownTextWrapper>
							<MotionDiv variants={fadeInRight}>
								<ScTitle isMobileLarge color={theme.colors.strongWhite} centered={!isDesktop}>
									{title}
								</ScTitle>
							</MotionDiv>
							<MotionDiv variants={fadeInRight}>
								<ScParagraph
									color={theme.colors.strongWhite}
									centered={!isDesktop}
									className="text"
								>
									{text}
								</ScParagraph>
							</MotionDiv>
							{!isDesktop && <ScImgWrapper><MotionImage variants={fadeInUp} src={image} alt="bread image" /></ScImgWrapper>}
							<MotionDiv variants={fadeInRight}>
								<ScReadyToStartSavingButtonsWrapper ref={ref}>
									{(typeOfScheme === TypeOfScheme.BIKE) ? (
										<CustomButton
											color={theme.colors.darkText}
											background={theme.colors.strongWhite}
											onClick={() => setIsOpenModal(true)}
										>
											Let’s talk
										</CustomButton>
									) : (
										<BookADemo link={link} label={'Let’s talk'} />
									)}
								</ScReadyToStartSavingButtonsWrapper>
							</MotionDiv>
						</ScBrownTextWrapper>
						{isDesktop && <ScImgWrapper><MotionImage variants={fadeInLeft} src={image} alt="bread image" /></ScImgWrapper>}
					</ScBrownWrapper>
				</ScContainer>
			</motion.section>
		</>
	);
};
