import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInLeft, fadeInRight } from '../../animations/variants';
import image from '../../assets/images/tech-ee-shema.png';
import { ScContainer } from '../../components/container/styled';
import { CustomButton } from '../../components/custom-button';
import { ScMainText } from '../../components/Text/styled';
import { ScTitleMedium } from '../../components/Title/styled';
import { ScTop } from '../../components/Top/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import { ScCycleToWorkSchemeHeroButtons } from '../TechScheme/styled';

import { ScCycleToWorkSchemeHeroWrapper, ScTitleWrapper } from './styled';

export const Hero = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section ref={ref} initial="hidden" animate={controls} variants={animationContainer}>
			<ScTop background={theme.colors.toasted} disableHeight={true}>
				<ScContainer>
					<ScCycleToWorkSchemeHeroWrapper>
						<ScTitleWrapper>
							<MotionDiv className="image" variants={fadeInRight}>
								<img src={image} alt="bread" />
							</MotionDiv>
							<MotionDiv variants={fadeInLeft}>
								<ScTitleMedium className="hero-title" color={theme.colors.darkText}>
									Help employees
									<br />
									save on tech
								</ScTitleMedium>
								<ScMainText isMobileLarge>
									Gogeta Tech saves employees money and there is zero cost to your business.
								</ScMainText>
								<ScCycleToWorkSchemeHeroButtons className={'buttons'}>
									<Link to="/employee-tech/">
										<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
											I'm an employee
										</CustomButton>
									</Link>
									<Link to="/employer-tech/">
										<CustomButton color={theme.colors.darkText} background={theme.colors.wheat}>
											I'm an employer
										</CustomButton>
									</Link>
								</ScCycleToWorkSchemeHeroButtons>
							</MotionDiv>
						</ScTitleWrapper>
					</ScCycleToWorkSchemeHeroWrapper>
				</ScContainer>
			</ScTop>
		</motion.section>
	);
};
