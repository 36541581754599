import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import styled, { useTheme } from 'styled-components';

import { getSchemeWebsite, isNotSSR, TypeOfScheme } from '@utils';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInRight } from '../../animations/variants';
import { ScContainer } from '../container/styled';
import { CustomButton } from '../custom-button';
import { ScParagraph } from '../Paragraph/styled';
import { ScTitle } from '../Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

export const SignUp = ({ typeOfScheme }: { typeOfScheme: TypeOfScheme }) => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<motion.section initial="hidden" animate={controls} variants={animationContainer}>
			<ScSignupContainer>
				<ScContainer>
					<ScSignUpWrapper>
						<ScSignUpTextWrapper>
							<MotionDiv variants={fadeInRight}>
								<ScTitle isMobileLarge color={theme.colors.strongWhite} centered>
									Sign up
								</ScTitle>
							</MotionDiv>
							<MotionDiv variants={fadeInRight}>
								<ScParagraph color={theme.colors.strongWhite} centered className="text" ref={ref}>
									Get signed up and start helping your team’s salary go further now
								</ScParagraph>
							</MotionDiv>
							<MotionDiv variants={fadeInRight}>
								{typeOfScheme === TypeOfScheme.BIKE ? (
									<Link to={'/employer-cycling/plans/'}>
										<CustomButton
											color={theme.colors.darkText}
											background={theme.colors.strongWhite}
										>
											Let’s go
										</CustomButton>
									</Link>
								) : (
									isNotSSR && (
										<CustomButton
											color={theme.colors.darkText}
											background={theme.colors.strongWhite}
											onClick={() => {
												isNotSSR &&
													(window.location.href = `${getSchemeWebsite(
														typeOfScheme,
													)}/portal/employer_portal/register`);
											}}
										>
											Let’s go
										</CustomButton>
									)
								)}
							</MotionDiv>
						</ScSignUpTextWrapper>
					</ScSignUpWrapper>
				</ScContainer>
			</ScSignupContainer>
		</motion.section>
	);
};

export const ScSignUpWrapper = styled.div`
	background: ${({ theme }) => theme.colors.marmelade};
	border-radius: 10px;
	padding: 30px 15px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	margin-bottom: 8px;
	@media (min-width: 1220px) {
		border-radius: 30px;
		padding: 100px;
		margin-bottom: 0;
	}
`;

export const ScSignupContainer = styled.div`
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: rgb(255, 255, 255);
`;

export const ScSignUpTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	button {
		margin-top: 10px;
	}
	max-width: 465px;
	@media (min-width: 1220px) {
		gap: 50px;
	}
`;
